.heading {
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
  color: #333;
  margin-top: 10;
}

.description {
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 10;
  color: #333;
}

.wordCount {
  font-size: 13px;
}

.button {
  border-radius: 30px;
  padding-inline: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: black;
  font-size: 15px;
}
/* Home.module.css */
.hide-on-tablet {
  display: none; 
}

/* Home.module.css */
@media (max-width: 992px) {
  .hide-on-tablet {
    display: none;
  }
}