.date{
    font-size: 15px;
    margin-top: -10px;
    color: gray;
}

.title{
    font-size: 20px;
    margin-top: 10px;
    color: black;
}

.heading {
    font-size: 2.5rem;
    font-weight: 600;
    /* text-align: center; */
    color: #333;
    margin-top: 10;
  }
  
  .description {
    font-size: 1.2rem;
    font-weight: 500;
    /* text-align: center; */
    margin-bottom: 10;
    color: #333;
  }
  
  .fieldHeading {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 10;
    color: #333;
    margin-left: 50px;
  }

